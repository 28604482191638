import NextI18Next from 'next-i18next';

export const i18n = new NextI18Next({
  defaultLanguage: 'pt',
  otherLanguages: ['en'],
  fallbackLng: 'pt',
  browserLanguageDetection: false,
  serverLanguageDetection: false,
  initImmediate: true,
});

export default i18n;
export const { appWithTranslation, withTranslation, Link, Router } = i18n;
